import { makeStyles } from 'tss-react/mui';

const useFormStyles = makeStyles()((theme) => ({
  formRoot: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  formHeader: {
    backgroundColor: 'rgba(15,170,245,50)',
    padding: theme.spacing(2, 3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formFooter: {
    padding: theme.spacing(0, 3, 3, 3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 0),
    },
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
  },
  closeButton: {
    color: 'ivory',
  },
}));

export default useFormStyles;
