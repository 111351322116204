import React from 'react';
import { Box, Typography } from '@mui/material';
import VendorForm from './VendorForm';
import useVendorStyles from './vendorStyles';
import UnderConstructionPage from '../UnderConstruction';

interface VendorsTemplateProps {
  title: string;
  mainTextContent: string[] | JSX.Element[];
  pageEnabled?: boolean;
}

const VendorsTemplate = ({
  mainTextContent,
  title,
  pageEnabled,
}: VendorsTemplateProps): JSX.Element => {
  const { classes } = useVendorStyles();
  return (
    <main>
      {pageEnabled ? (
        <>
          <Box id="upload-target"></Box>
          <section>
            <Box>
              <Typography variant="h4">{title}</Typography>
              <Box className={classes.mainContent}>
                <article>
                  {mainTextContent.map((content, idx) => (
                    <p key={`mainContent_${idx}`}>{content}</p>
                  ))}
                </article>

                <VendorForm />
              </Box>
            </Box>
          </section>
        </>
      ) : (
        <UnderConstructionPage />
      )}
    </main>
  );
};

export default VendorsTemplate;
