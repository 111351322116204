import React from 'react';

import RefreshIcon from '@mui/icons-material/Refresh';
import useLoadingSpinnerStyles from './loadingSpinnerStyles';
import { Box, Typography, TypographyOwnProps } from '@mui/material';

interface LoadingSpinnerProps {
  text?: string;
  color?: React.CSSProperties['color'];
  width?: string | number;
  display?: React.CSSProperties['display'];
  justifyContent?: React.CSSProperties['justifyContent'];
  alignItems?: React.CSSProperties['alignItems'];
  typography?: TypographyOwnProps['variant'];
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  color = 'orange',
  text = 'Loading',
  alignItems = 'center',
  display = 'flex',
  justifyContent = 'flex-start',
  width = '100%',
  typography = 'h5',
}) => {
  const { classes } = useLoadingSpinnerStyles();

  return (
    <Box
      style={{
        width,
        display,
        justifyContent,
        alignItems,
      }}
    >
      <RefreshIcon
        className={classes.spinner}
        style={{
          color: color,
        }}
      />
      <Typography
        style={{
          pointerEvents: 'none',
          width: '45%',
        }}
        variant={typography}
        color={color}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default LoadingSpinner;
