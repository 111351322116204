import { makeStyles } from 'tss-react/mui';

const useFooterStyles = makeStyles()((theme) => ({
  footer: {
    background: '#000',
    bottom: 0,
    color: theme.palette.background.paper,
  },
  footerContent: {
    margin: 0,
    padding: theme.spacing(5, 9, 9, 9),
    listStyle: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    '&>li>a': {
      textDecoration: 'none',
      color: theme.palette.background.paper,
      '&:hover': {
        color: '#e4bb3d',
      },
    },
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(3),
      gap: 20,
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: theme.spacing(3),
      gap: 20,
    },
  },
}));

export default useFooterStyles;
