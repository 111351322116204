import { makeStyles } from 'tss-react/mui';

const useToutStyles = makeStyles()((theme) => ({
  mainToutWrap: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
    '&>div': {
      width: '48%',
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
      },
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: 'auto',
    },
  },
  image: {
    borderRadius: '10px',
    width: '100%',
    maxHeight: 450,
    maxWidth: 600,
  },
}));

export default useToutStyles;
