import React from 'react';

const Renaissance: React.FC = () => {
  return (
    <>
      If you need distribution in Northern California, please visit our sister
      company,{' '}
      <a
        href="https://www.renaissancespecialtyfoods.com/"
        target="_blank"
        rel="noreferrer"
      >
        Renaissance Specialty Foods
      </a>
      .
    </>
  );
};

export default Renaissance;
