type PageStatus = {
  available?: boolean;
};

export interface MainContextState {
  mainContextPageState: {
    home: PageStatus;
    ourCustomers: PageStatus;
    vendorsWelcome: PageStatus;
    about: PageStatus;
    contact: PageStatus;
  };
}

export type EnableHomePage = {
  type: 'enableHomePage';
  payload: Pick<MainContextState['mainContextPageState'], 'home'>;
};
export type EnableOurCustomers = {
  type: 'enableOurCustomers';
  payload: Pick<MainContextState['mainContextPageState'], 'ourCustomers'>;
};
export type EnableVendorsWelcome = {
  type: 'enableVendorsWelcome';
  payload: Pick<MainContextState['mainContextPageState'], 'vendorsWelcome'>;
};
export type EnableAbout = {
  type: 'enableAbout';
  payload: Pick<MainContextState['mainContextPageState'], 'about'>;
};
export type EnableAContact = {
  type: 'enableContact';
  payload: Pick<MainContextState['mainContextPageState'], 'contact'>;
};

export type MainContextPageAccessActions =
  | EnableHomePage
  | EnableOurCustomers
  | EnableVendorsWelcome
  | EnableAbout
  | EnableAContact;

export const initialState: MainContextState = {
  mainContextPageState: {
    about: {
      available: true,
    },
    contact: {
      available: true,
    },
    home: {
      available: true,
    },
    ourCustomers: {
      available: true,
    },
    vendorsWelcome: {
      available: false,
    },
  },
};

export const mainContextReducer = (
  state: MainContextState,
  action: MainContextPageAccessActions
): MainContextState => {
  switch (action.type) {
    case 'enableAbout':
      return {
        ...state,
        mainContextPageState: {
          ...state.mainContextPageState,
          about: action.payload.about,
        },
      };
    case 'enableContact':
      return {
        ...state,
        mainContextPageState: {
          ...state.mainContextPageState,
          about: action.payload.contact,
        },
      };
    case 'enableOurCustomers':
      return {
        ...state,
        mainContextPageState: {
          ...state.mainContextPageState,
          about: action.payload.ourCustomers,
        },
      };
    case 'enableHomePage':
      return {
        ...state,
        mainContextPageState: {
          ...state.mainContextPageState,
          about: action.payload.home,
        },
      };
    case 'enableVendorsWelcome':
      return {
        ...state,
        mainContextPageState: {
          ...state.mainContextPageState,
          about: action.payload.vendorsWelcome,
        },
      };
    default:
      throw new Error('You did not supply an action to dispatch');
  }
};
