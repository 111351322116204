import withHooks, { mapHooksToProps } from '../../common/utilities/withHooks';
import PartnerListTemplate, { Partner } from './PartnerList';
import parnterBadia from '../../assets/partner_badia.png';
import parnterLaTourangelle from '../../assets/partner_la_tourangelle.png';
import parnterRustichella from '../../assets/partner_rustichella.jpg';
import parnterSarabeth from '../../assets/partner_sarabeth.png';
import parnterscharfenn from '../../assets/partner_scharfenn.png';
import simpsons from '../../assets/simpsonbrands_large.jpg';
import stonewall from '../../assets/stonewall.png';
import { SyntheticEvent, useCallback } from 'react';

const hooks = mapHooksToProps(() => {
  const list: Partner[] = [
    {
      image: parnterBadia,
      url: 'https://www.coltibuono.com/en/',
      alt: 'Badia a Coltibuono',
    },
    {
      image: simpsons,
      url: 'https://www.simpsonbrands.com',
      alt: 'Simpson Brands',
    },
    {
      image: parnterLaTourangelle,
      url: 'https://latourangelle.com/',
      alt: 'Latourangelle',
    },
    {
      image: parnterRustichella,
      url: 'https://www.rustichella.it/',
      alt: 'Rustichella',
    },
    {
      image: parnterSarabeth,
      url: 'https://www.sarabethsrestaurants.com/',
      alt: 'Sara Beth',
    },
    {
      image: parnterscharfenn,
      url: 'https://www.scharffenberger.com/',
      alt: 'Scharffen Berger',
    },
    {
      image: stonewall,
      url: 'https://www.stonewallkitchen.com',
      alt: 'Stone Wall Kitchen',
    },
  ];

  const openUrl = useCallback((e: SyntheticEvent, url: string) => {
    const target = e.target as HTMLDivElement;

    const targetUrl = target.getAttribute('data-url') || '';

    window && window.open(targetUrl, '_targe="blank"');
  }, []);

  return {
    list,
    openUrl,
  };
});

const PartnerList = withHooks(PartnerListTemplate, hooks);

export default PartnerList;
