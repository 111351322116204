import React from 'react';
import useContactPageStyles from './contactPageStyles';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material';
import PartnerList from '../../components/PartnerList';
import { ContactUsData } from '.';

interface ContactPageTemplateProps {
  cardData: ContactUsData[];
}

const ContactPageTemplate: React.FC<ContactPageTemplateProps> = ({
  cardData,
}) => {
  const { classes } = useContactPageStyles();
  return (
    <main>
      <section className={classes.mainWrapper}>
        <Box className={classes.sideNav}>
          <nav>
            <PartnerList />
          </nav>
        </Box>
        <Box className={classes.mainContentWrapper}>
          <Typography variant="h4">Contact Us</Typography>
          <Box className={classes.mainContent}>
            <article>
              {cardData.map((card) => (
                <Card className={classes.card} key={`card_${card.title}`}>
                  <CardContent>
                    <Typography variant="h6">{card.title}</Typography>
                    {card.contactInfo &&
                      card.contactInfo.map((contact, idx) => (
                        <Typography variant="body1" key={`contact_${idx}`}>
                          {contact}
                        </Typography>
                      ))}
                  </CardContent>
                  {card.cta && (
                    <CardActions>
                      {card.cta.url && (
                        <Button href={card.cta.url}>{card.cta.text}</Button>
                      )}
                    </CardActions>
                  )}
                </Card>
              ))}
            </article>
          </Box>
        </Box>
      </section>
    </main>
  );
};

export default ContactPageTemplate;
