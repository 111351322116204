import { useEffect, useRef, useState } from 'react';
import withHooks, { mapHooksToProps } from '../../common/utilities/withHooks';
import HTMLFileUploaderTemplate from './HTMLFileUploader';
import {
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from 'react-hook-form';

export const acceptedFileTypes = ['image/png', ' image/jpeg'] as const;

export type ArrayOfAcceptedFileTypes = typeof acceptedFileTypes;
interface HTMLFileUploaderProps {
  field: ControllerRenderProps<FieldValues, 'file'>;
  acceptedTypes?: ['image/png', ' image/jpeg'];
}

export type UploadedFile = {
  fileName: string;
  blob: string;
};
const hooks = mapHooksToProps(
  ({ field, acceptedTypes }: HTMLFileUploaderProps) => {
    const fileReader = useRef(new FileReader());
    const [fileResult, setFileResult] = useState<UploadedFile>({
      blob: '',
      fileName: '',
    });
    const [fileName, setFileName] = useState<string>('');
    const { register, getFieldState, getValues, formState, setValue } =
      useFormContext();

    const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
      /**
       * The uploaded file
       */
      const selectedFile = evt.target?.files?.[0];
      /**
       * Set the selected file as the blob
       */
      fileReader.current.readAsDataURL(selectedFile || new File([], 'temp'));

      /**
       * Set the name of the file for use in the UI
       * and as for use with the name of the file sent to the back-end
       */
      setFileName(selectedFile?.name || '');

      /**
       * Clear the previous result from the file to
       * reduce the chance of the previous image
       * being displayed if the form is cleared.
       */
      setFileResult({
        blob: '',
        fileName: '',
      });
    };

    useEffect(() => {
      Object.keys(formState.dirtyFields).length === 0 && setFileName('');

      fileReader.current.onload = (evt) => {
        /**
         * Set values here for use in the UI
         */
        setFileResult({
          blob: evt.target?.result?.toString() || '',
          fileName,
        });

        /**
         * We need to send this finalResult as the image data to the back end
         */
        setValue('file', {
          fileName,
          blob: evt.target?.result?.toString() || '',
        });
      };
    }, [
      field,
      field.name,
      fileName,
      fileResult,
      formState.dirtyFields,
      getFieldState,
      getValues,
      setValue,
    ]);

    return {
      field,
      acceptedTypes,
      onChange,
      fileResult,
      register,
    };
  }
);

const HTMLFileUploader = withHooks(HTMLFileUploaderTemplate, hooks);

export default HTMLFileUploader;
