import { makeStyles } from 'tss-react/mui';

const useHtmlFileUploaderStyles = makeStyles()((theme) => ({
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
    },
    '&>div': {
      width: '48%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        '&>div:last-child': {
          marginTop: theme.spacing(2),
        },
      },
    },
  },
  imageBox: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  fileImage: {
    maxWidth: '100%',
    borderRadius: 10,
  },
}));

export default useHtmlFileUploaderStyles;
