interface ExternalFooterLinks {
  url: string;
}

const externalFooterLinks: ExternalFooterLinks[] = [
  {
    url: 'https://www.google.com/maps/place/16511+Main+St,+Gardena,+CA+90248/@33.8817158,-118.2786905,17z/data=!3m1!4b1!4m6!3m5!1s0x80c2ca933603accd:0xa97dd3049b36716d!8m2!3d33.8817158!4d-118.2761156!16s%2Fg%2F11c4k6t1sd?hl=en&entry=ttu',
  },
];

export default externalFooterLinks;
