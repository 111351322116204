import { StandardTextFieldProps, TextField, Typography } from '@mui/material';
import React from 'react';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';

interface FormTextFieldProps extends StandardTextFieldProps {
  errorMessage?:
    | string
    | FieldError
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | Merge<FieldError, FieldErrorsImpl<any>>
    | undefined;
}

const FormTextField: React.FC<FormTextFieldProps> = ({
  errorMessage,
  ...field
}) => {
  return (
    <>
      <TextField
        style={{
          width: '100%',
        }}
        error={!!errorMessage}
        {...field}
      />
      {errorMessage && (
        <Typography color="red" variant="body1">
          {errorMessage as React.ReactNode}
        </Typography>
      )}
    </>
  );
};

export default FormTextField;
