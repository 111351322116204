// import withHooks, { mapHooksToProps } from '../../utilities/withHooks';
import { TypographyOwnProps } from '@mui/material';
import withHooks, { mapHooksToProps } from '../../common/utilities/withHooks';
import ToutTemplate from './Tout';

interface ToutProps {
  imageUrl?: string;
  title?: string;
  bodyText?: string;
  bodyTextColor?: TypographyOwnProps['color'];
  bodyTextVariant?: TypographyOwnProps['variant'];
  ctaConfig?: {
    text: string;
    onCLick?: () => void;
  };
  flexDirection?: 'row-reverse' | 'column-reverse' | 'column' | 'row';
  alignToutContent?:
    | 'center'
    | 'normal'
    | 'baseline'
    | 'flex-start'
    | 'flex-end';
  children?: React.ReactNode;
}

const hooks = mapHooksToProps(
  ({
    bodyText,
    ctaConfig,
    imageUrl,
    title,
    flexDirection,
    bodyTextVariant,
    bodyTextColor,
    alignToutContent,
    children,
  }: ToutProps) => {
    return {
      bodyText,
      bodyTextVariant,
      bodyTextColor,
      ctaConfig,
      imageUrl,
      title,
      flexDirection,
      alignToutContent,
      children,
    };
  }
);

const Tout = withHooks(ToutTemplate, hooks);

export default Tout;
