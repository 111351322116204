import { makeStyles } from 'tss-react/mui';

const useVendorFormStyles = makeStyles()((theme) => ({
  formRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'row wrap',
    gap: theme.spacing(3),
  },
  formJacket: {
    background: '#000',
  },
  formInsert: {
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'row wrap',
    gap: theme.spacing(3),
    background: '#ffffff',
    paddingTop: theme.spacing(4),
    borderRadius: '15px 15px 0px 0px ',
  },
  fullLength: {
    width: 'fill-available',
    '&>div': {
      width: '100%',
    },
  },
  heading: {
    padding: theme.spacing(3),
    textAlign: 'center',
  },
  input: {
    width: '46%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export default useVendorFormStyles;
