import { useCallback, useState } from 'react';
import withHooks, { mapHooksToProps } from '../../common/utilities/withHooks';
import HeaderTemplate from './Header';
export type Links = {
  url: string;
  title: string;
};

interface HeaderProps {
  links: Links[];
}

const hooks = mapHooksToProps(({ links }: HeaderProps) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const toggleDialog = useCallback(() => {
    setDialogOpen(!dialogOpen);
  }, [dialogOpen]);

  return {
    links,
    toggleDialog,
    dialogOpen,
  };
});

const Header = withHooks(HeaderTemplate, hooks);

export default Header;
