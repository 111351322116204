import React from 'react';

import { DistributorLinks } from '.';
import { Box, Typography } from '@mui/material';
import useHomePageStyles from './styles';
import iceCream from '../../assets/gourmet-icecream.jpg';
import PartnerList from '../../components/PartnerList';

interface HomePageTemplateData {
  title: string;
  distributors: {
    title: string;
    links: DistributorLinks[];
  };
  textContent: {
    main: (string | JSX.Element)[];
    title: string;
  };
}

const HomePage = ({
  distributors,
  title,
  textContent,
}: HomePageTemplateData) => {
  const { classes } = useHomePageStyles();
  return (
    <main>
      <section className={classes.mainWrapper}>
        <Box className={classes.sideNav}>
          <nav>
            <PartnerList />
          </nav>
        </Box>
        <Box className={classes.mainContentWrapper}>
          <Typography variant="h4">{textContent.title}</Typography>
          <Box className={classes.mainContent}>
            <Box display="flex" flexDirection="column">
              {textContent.main.map((text, idx) => (
                <article key={`text_${idx}`}>{text}</article>
              ))}
            </Box>
            <img className={classes.mainImage} src={iceCream} alt="ice-cream" />
            {/* <a href="https://www.vecteezy.com/free-photos/dessert">
              Dessert Stock photos by Vecteezy
            </a> */}
          </Box>
        </Box>
      </section>
    </main>
  );
};
export default HomePage;
