import withHooks, { mapHooksToProps } from '../../common/utilities/withHooks';

import SpecialtyPageTemplate from './SpecialtyPage';
export type PageImages = {
  url: string;
  image: string;
};
const hooks = mapHooksToProps(() => {
  const pageText = [
    'GMI was founded to sell estate grown olive oils and artisnal pastas. While these categories are still important, our business has grown to include high quality artisan-style products in all non-perishable categories.  Chocolates, jams, sauces, condiments, crackers, cookies, spices...all the products that a gourmet pantry should contain are part of our product offerings.',
    '',
  ];

  const pageImages: PageImages[] = [
    {
      image: '',
      url: '',
    },
  ];

  return {
    pageText,
    pageImages,
  };
});

const SpecialtyPage = withHooks(SpecialtyPageTemplate, hooks);

export default SpecialtyPage;
