import React from 'react';
import './App.css';
import PageWrapper from './PageWrapper/PageWrapper';
import MainContextProvider from './Providers/MainContextProvider/MainContextProvider';

function App() {
  // App is now a wrapper for the page router
  return (
    <MainContextProvider>
      <PageWrapper />
    </MainContextProvider>
  );
}

export default App;
