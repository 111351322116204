import withHooks, { mapHooksToProps } from '../../common/utilities/withHooks';
import AboutPageTemplate from './AboutPage';

import table from '../../assets/640-restaurant-table.jpg';
import stylishDish from '../../assets/640-stylish-dish.jpeg';

export type DistributorLinks = {
  image: string;
  url: string;
};
export interface AboutPageData {
  title: string;
  distributors: {
    title: string;
    links: DistributorLinks[];
  };
  textContent: {
    main: string[];
    title: string;
    images?: string[];
  };
}
const hooks = mapHooksToProps(() => {
  const aboutPageData: AboutPageData = {
    title: 'About GMI',
    distributors: {
      links: [
        {
          image: '',
          url: '',
        },
      ],
      title: 'Distributors',
    },
    textContent: {
      main: [
        `GMI distributes an exciting variety of domestic and imported specialty food products ranging from hand crafted European traditions to the most innovative items that the new generation of American chefs and entrepreneurs are creating. We are a company that is committed to serving the gourmet and natural marketplace and our focus remains on providing an array of foods that will make our customer’s product mix stand out from the everyday.`,
        `GMI is a company that is passionate about good food. We welcome the opportunity to share our excitement about the wonderful companies whose products we proudly distribute to our customers.`,
      ],
      title: 'About GMI',
      images: [table, stylishDish],
    },
  };

  return aboutPageData;
});

const AboutPage = withHooks(AboutPageTemplate, hooks);

export default AboutPage;
