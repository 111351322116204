import { makeStyles } from 'tss-react/mui';

const useUnderConstructionStyles = makeStyles()((theme) => ({
  mainWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  mainContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '2rem',
    },
  },
}));

export default useUnderConstructionStyles;
