import * as yup from 'yup';

const phoneNumberRegEx =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const emailPattern =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const vendorFormSchema = yup.object().shape({
  firstName: yup.string().required('You must provide your first name'),
  lastName: yup.string().required('You must provide your last name'),
  company: yup.string().required('You must provide the company name'),
  title: yup.string().required('You must provide a title for this email'),
  phone: yup
    .string()
    .matches(phoneNumberRegEx, 'The phone number format is invalid')
    .required('You must Your phone number'),
  fax: yup
    .string()
    .matches(phoneNumberRegEx, 'The fax number format is invalid'),
  email: yup
    .string()
    .email('You must enter a valid email address (i.e: myEmail@someClient.com)')
    .matches(
      emailPattern,
      'You must enter a valid email address (i.e: myEmail@someClient.com)'
    )
    .required('Please provide your email address'),
  website: yup.string().required('Please pride your website url'),
  address: yup.string().required('Please provide an address'),
  address2: yup.string(),
  city: yup.string().required('Please provide your city'),
  stateProvince: yup.string().required('Please provide the state/province'),
  postalCode: yup.string().required('Please provide the postal code'),
  country: yup.string().required('Please provide the country'),
  description: yup.string().required('Please provide a product description'),
  file: yup.object().shape({
    fileName: yup.string(),
    blob: yup.string(),
  }),
  selectedFile: yup.string(),
});

export default vendorFormSchema;
