import React, { createContext, useReducer } from 'react';
import {
  initialState,
  MainContextPageAccessActions,
  mainContextReducer,
  MainContextState,
} from './store/MainStore';

export interface MainContextProviderProps {
  mainContextState: MainContextState;
  setMainContextState: React.Dispatch<MainContextPageAccessActions>;
}

interface MainContextProviderChildProps {
  children?: React.ReactNode;
}

export const MainContext = createContext<MainContextProviderProps>({
  mainContextState: {
    ...initialState,
  },
  setMainContextState: () => {
    throw new Error(
      'Attempted to call setMainContextState without the MainContextProvider'
    );
  },
});

MainContext.displayName = 'MainContext';

const MainContextProvider: React.FC<MainContextProviderChildProps> = ({
  children,
}) => {
  // need the stuff from the store
  const [mainContextState, setMainContextState] = useReducer(
    mainContextReducer,
    initialState
  );
  const mainContextProviderProps: MainContextProviderProps = {
    mainContextState,
    setMainContextState,
  };

  return (
    <MainContext.Provider value={mainContextProviderProps}>
      {children}
    </MainContext.Provider>
  );
};

export default MainContextProvider;
