import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import EngineeringIcon from '@mui/icons-material/Engineering';
import useUnderConstructionStyles from './underConstructionStyles';

interface UnderConstructionTemplateProps {
  title: string;
  mainTextContent: string[] | JSX.Element[];
}

const UnderConstructionTemplate: React.FC<UnderConstructionTemplateProps> = ({
  mainTextContent,
  title,
}) => {
  const { classes } = useUnderConstructionStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box className={classes.mainWrapper}>
      <Box className={classes.mainContent}>
        <Typography variant={isMobile ? 'h4' : 'h2'}>{title}</Typography>
        <EngineeringIcon color="error" sx={{ fontSize: '10rem' }} />
      </Box>
      <Typography variant={isMobile ? 'h5' : 'h4'}>
        {mainTextContent}
      </Typography>
    </Box>
  );
};
export default UnderConstructionTemplate;
