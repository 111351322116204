import withHooks, { mapHooksToProps } from '../../common/utilities/withHooks';
import ContactPageTemplate from './ContactPage';

export interface ContactUsData {
  title?: string;
  contactInfo?: string[];
  cta?: {
    text: string;
    url: string;
  };
}
const hooks = mapHooksToProps(() => {
  const cardData: ContactUsData[] = [
    {
      contactInfo: ['Shelly-Ann Whyte', '(310) 808-0960 x 303'],
      title: 'ACCOUNTING MANAGER:',
      cta: {
        text: 'shelly.whyte@gmidist.com',
        url: 'mailto:shelly.whyte@gmidist.com',
      },
    },
    {
      contactInfo: ['Stephanie Bernard', '(310) 808-0960 x 303'],
      title: 'Office Manager:',
      cta: {
        text: 'stephanie.bernard@gmidist.com',
        url: 'mailto:stephanie.bernard@gmidist.com',
      },
    },
    {
      contactInfo: ['Pennie Miyahara-Cappasola', '(310) 808-0960 x 300'],
      title: 'SALES ADMINISTRATOR:',
      cta: {
        text: 'pennie.cappasola@gmidist.com',
        url: 'mailto:pennie.cappasola@gmidist.com',
      },
    },
    {
      contactInfo: ['Alfredo Morales', '(310) 808-0960 x 309'],
      title: 'WAREHOUSE SUPERVISOR:',
    },
    {
      contactInfo: ['Bob Chavelle', '(310) 808-0960 x 307'],
      title: 'PRESIDENT:',
      cta: {
        text: 'bob.chavelle@gmidist.com',
        url: 'mailto:bob.chavelle@gmidist.com',
      },
    },
    {
      contactInfo: ['Seth Feldman', '(310) 808-0960 x 306'],
      title: 'Sales Consultant:',
      cta: {
        text: 'seth.feldman@gmidist.com',
        url: 'mailto:seth.feldman@gmidist.com',
      },
    },
    {
      contactInfo: ['Kevin Shakes', '(310) 808-0960 x 310'],
      title: 'WAREHOUSE/PURCHASING MANAGER:',
      cta: {
        text: 'seth.feldman@gmidist.com',
        url: 'mailto:kevin.shakes@gmidist.com',
      },
    },
    {
      contactInfo: ['Aileen Kawamoto', '(310) 310-808-0960 x 302'],
      title: 'Order Desk:',
      cta: {
        text: 'aileen.kawamoto@gmidist.com',
        url: 'mailto:aileen.kawamoto@gmidist.com',
      },
    },
    {
      contactInfo: ['Paola Jasso', '(310) 310-808-0960 x 309'],
      title: 'Warehouse Manager:',
      cta: {
        text: 'paola.jasso@gmidist.com',
        url: 'mailto:paola.jasso@gmidist.com',
      },
    },
    {
      contactInfo: ['Steve Meyer', '(310) 310-808-0960 x 365'],
      title: 'Director of Sales & Operations:',
      cta: {
        text: 'steve.meyer@gmidist.com',
        url: 'mailto:steve.meyer@gmidist.com',
      },
    },
    {
      contactInfo: ['Direct', '(310) 808-0960'],
      title: 'OFFICE:',
    },
    {
      title: 'ADDRESS:',
      cta: {
        text: '16511 S. Main Street Gardena California 90248 USA',
        url: 'https://www.google.com/maps/place/16511+Main+St,+Gardena,+CA+90248/@33.8817158,-118.2786905,17z/data=!3m1!4b1!4m6!3m5!1s0x80c2ca933603accd:0xa97dd3049b36716d!8m2!3d33.8817158!4d-118.2761156!16s%2Fg%2F11c4k6t1sd?hl=en&entry=ttu',
      },
    },
    {
      contactInfo: ['Direct', '(310) 310-808-0960 x 309'],
      title: 'Warehouse:',
      cta: {
        text: 'warehouse@gmidist.com',
        url: 'mailto:warehouse@gmidist.com',
      },
    },
    {
      contactInfo: ['Fax', '(310) 310-808-0964'],
      title: 'Fax Line:',
      cta: {
        text: '',
        url: '',
      },
    },
  ];
  return {
    cardData,
  };
});

const ContactPage = withHooks(ContactPageTemplate, hooks);

export default ContactPage;
