import withHooks, { mapHooksToProps } from '../../common/utilities/withHooks';
import useMainContextProvider from '../../Providers/MainContextProvider/useMainContextProvider';
import VendorsTemplate from './Vendors';

interface VendorsProps {
  title: string;
  mainTextContent: string[] | JSX.Element[];
  pageEnabled?: boolean;
}
const hooks = mapHooksToProps(() => {
  const { mainContextState } = useMainContextProvider();

  const { available } = mainContextState.mainContextPageState.vendorsWelcome;

  const pageData: VendorsProps = {
    mainTextContent: [
      'Our true value is that we have a long–standing relationship with the buyers at the five major gourmet chains – Gelsons, Bristol Farms, Erewhon, Lazy Acres and Whole Foods Market as well as many independent gourmet markets throughout Southern California. We are a regional distributor that offers our artisan manufacturers and vendors a direct path to retailers through our intimate knowledge of the local market and our personal involvement with our buyers and chain category managers That will give you a quick to market response!',
      'The differences between GMI and the larger national distributors is that they have creatively developed vendor funded revenue streams to offset their unsustainably low margins. Warehouse slotting fees, high chargeback rates based on full list price, fee-based distributor marketing programs, fee based “preferred” vendor status and required participation at in-house trade shows are among the many revenue enhancement strategies that are required of their suppliers. WE DON’T DO THAT! At GMI, by implementing a lower cost price level reflecting an EVERY DAY LOW DISTRIBUTOR COST structure, vendors can level the playing field to everyone’s advantage. Appropriately stripping the funding for fee-based distributor programs from the price will allow you to go through the marketplace at an appropriate, right sized channel, retaining the benefits of working with more focused trading partners.',
      'In addition, our seasoned sales team, will keep a personal eye on your stock and set and will be an excellent tool to get you into the strong independent stores throughout the Southern California Region.',
    ],
    title: 'Vendors Welcome',
    pageEnabled: available,
  };
  return {
    ...pageData,
  };
});

const Vendors = withHooks(VendorsTemplate, hooks);

export default Vendors;
