import { makeStyles } from 'tss-react/mui';

const useContactPageStyles = makeStyles()((theme) => ({
  mainWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexShrink: 0,
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column-reverse',
      gap: 24,
    },
  },
  sideNav: {
    width: '33%',
    flexShrink: 0,
    maxWidth: theme.spacing(40),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
  mainContent: {
    maxWidth: theme.spacing(176),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: 24,
    },
    '&>article': {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      width: 'auto',
      gap: 25,
    },
  },
  mainContentWrapper: {
    marginLeft: theme.spacing(4),
    minWidth: '70%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: 0,
    },
  },
  card: {
    width: '45%',
    '&>div>h6': {
      textTransform: 'uppercase',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export default useContactPageStyles;
