import React from 'react';

import withHooks, { mapHooksToProps } from '../../common/utilities/withHooks';

import FooterTemplate from './Footer';
import externalFooterLinks from './externalFooterLinks';

const hooks = mapHooksToProps(() => {
  const year = new Date().getFullYear();
  //@ts-ignore
  const footerData: string[] | JSX.Element[] = [
    <span>
      {' '}
      &copy; {year} Gourmet Merchants International. All Rights Reserved.
    </span>,
    <a href={externalFooterLinks[0].url} target="_blank" rel="noreferrer">
      16511 S. Main Street, Gardena California 90248, USA
    </a>,
    '(310) 808-0960 Office (310) 808-0964 Fax',
  ];

  return {
    footerData,
  };
});

const Footer = withHooks(FooterTemplate, hooks);

export default Footer;
