import React from 'react';

import {
  DeepPartial,
  FieldValues,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  UnpackNestedValue,
  UseFormReturn,
  ValidationMode,
} from 'react-hook-form';

import { Box, Button, IconButton, Typography } from '@mui/material';
import useFormStyles from './formStyle';
import CloseIcon from '@mui/icons-material/Close';
import { DevTool } from '@hookform/devtools';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// type NoInfer<T> = [T][T extends any ? 0 : never];
export interface FormComponentTemplateProps<T> {
  formName: string;
  formHeader?: string;
  formDescription?: string;
  initialValue: UnpackNestedValue<DeepPartial<T>>;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  onSubmit: SubmitHandler<FieldValues>;
  onCancel: () => void;
  children:
    | ((
        form: UseFormReturn<FieldValues>,
        onSubmit: SubmitHandler<FieldValues>
      ) => React.ReactNode)
    | React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationSchema?: any;
  mode?: keyof ValidationMode | undefined;
  onError?: SubmitErrorHandler<FieldValues>;
  form: UseFormReturn<FieldValues>;
  closeButton?: boolean;
  submitButtonText?: string;
  cancelButtonText?: string;
  submitDisabled?: boolean;
  hideFormCTAOnSuccess?: boolean;
}

const FormComponent = <T,>({
  formName,
  onCancel,
  onSubmit,
  formHeader,
  children,
  form,
  closeButton,
  submitButtonText,
  cancelButtonText,
  submitDisabled,
}: FormComponentTemplateProps<T>): JSX.Element => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isSubmitSuccessful },
    reset,
  } = form;

  const { classes } = useFormStyles();
  const resetForm = () => {
    reset();
    typeof onCancel !== 'undefined' && onCancel();
  };
  return (
    <FormProvider {...form}>
      <Box width={'fill-available'}>
        <form name={formName} onSubmit={handleSubmit(onSubmit)}>
          {formHeader && (
            <Box className={classes.formHeader}>
              <Typography variant="h5" color="ivory">
                {formHeader}{' '}
              </Typography>
              {closeButton && (
                <IconButton className={classes.closeButton} onClick={onCancel}>
                  <CloseIcon />
                </IconButton>
              )}
            </Box>
          )}
          <Box className={classes.formRoot}>
            {typeof children === 'function'
              ? children(form, onSubmit)
              : children}
          </Box>
          <Box className={classes.formFooter}>
            <Box>
              <Button onClick={resetForm} variant="outlined" color="error">
                {cancelButtonText}
              </Button>
            </Box>
            <Box>
              <Button
                disabled={submitDisabled || isSubmitting || isSubmitSuccessful}
                type="submit"
                variant="outlined"
                color="primary"
              >
                {submitButtonText}
              </Button>
            </Box>
          </Box>
          {process.env.NEXT_PUBLIC_ENABLE_REACT_HOOK_FORM_DEV_TOOL ===
            'true' && <DevTool control={control} />}
        </form>
      </Box>
    </FormProvider>
  );
};

export default FormComponent;
