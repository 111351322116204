import React, { useCallback, useEffect, useState } from 'react';
import FormComponent from '../../components/FormComponent';
import vendorFormSchema from './vendorFormSchema';
import { Box, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import FormTextField from '../../components/FormComponent/FormTextField';
import useVendorFormStyles from './vendorFormStyles';
import { DevTool } from '@hookform/devtools';
import HTMLFileUploader from '../../components/HTMLFileUploader';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import CircularJSON from 'circular-json';
interface VendorFormValues {
  firstName: string;
  lastName: string;
  company: string;
  title: string;
  phone: string;
  fax: string;
  email: string;
  website: string;
  address: string;
  address2: string;
  city: string;
  stateProvince: string;
  postalCode: string;
  country: string;
  description: string;
  file: {
    fileName: string;
    blob: string;
  };
  selectedFile: string;
}
const VendorForm = (): JSX.Element => {
  const initialValues = {
    firstName: '',
    lastName: '',
    company: '',
    title: '',
    phone: '',
    fax: '',
    email: '',
    website: '',
    address: '',
    address2: '',
    city: '',
    stateProvince: '',
    postalCode: '',
    country: '',
    description: '',
    file: {
      fileName: '',
      blob: '',
    },
    selectedFile: '',
  };

  const { reset } = useForm();
  const [formSentSuccessfully, setFormSentSuccessfully] =
    useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = useCallback(async (values: VendorFormValues) => {
    try {
      setLoading(true);
      // await fetch('http://api.mastermalone.com:4444/api/v1/contact', {
      await fetch('https://api.webmastermalone.com/api/v1/contact', {
        mode: 'no-cors',
        method: 'POST',
        body: CircularJSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((data) => {
          setFormSentSuccessfully(true);
          setLoading(false);
          if (data.status !== 200) {
            setFormSentSuccessfully(false);
          }
        })
        .catch((err) => {
          console.info('there was an error', err);
          setLoading(false);
          setFormSentSuccessfully(false);
        });
    } catch (err) {
      console.info('There was an error submitting the form', err);
      setFormSentSuccessfully(false);
    }

    return {
      ...values,
    };
  }, []);

  const onCancel = useCallback(() => {
    reset();
  }, [reset]);

  const { classes } = useVendorFormStyles();

  useEffect(() => {
    console.info('formSentSuccessfully', formSentSuccessfully);
  }, [formSentSuccessfully]);

  /**
   * I had to add ref={null} to the field to solve a
   * warning bug in the console that described the
   * ref as being unable to be resolved
   */
  return (
    <FormComponent
      formName="vendorForm"
      initialValue={initialValues}
      cancelButtonText="Clear"
      onCancel={onCancel}
      //@ts-ignore
      onSubmit={onSubmit}
      validationSchema={vendorFormSchema}
    >
      {({ control, formState: { errors, isSubmitSuccessful } }) => (
        <Box className={classes.formRoot}>
          <Box className={classes.formJacket}>
            <Box>
              <Typography
                className={classes.heading}
                variant="h4"
                color="white"
              >
                Contact Us
              </Typography>
            </Box>
            <Box className={classes.formInsert}>
              <Controller
                control={control}
                name="firstName"
                render={({ field }) => (
                  <Box className={classes.input}>
                    <FormTextField
                      label="First Name"
                      placeholder="Enter your first name"
                      type="text"
                      errorMessage={errors?.firstName?.message}
                      {...field}
                      ref={null}
                    />
                  </Box>
                )}
              />
              <Controller
                control={control}
                name="lastName"
                render={({ field }) => (
                  <Box className={classes.input}>
                    <FormTextField
                      label="Last Name"
                      placeholder="Enter your last name"
                      type="text"
                      errorMessage={errors?.lastName?.message}
                      {...field}
                      ref={null}
                    />
                  </Box>
                )}
              />
              <Controller
                control={control}
                name="company"
                render={({ field }) => (
                  <Box className={classes.input}>
                    <FormTextField
                      label="Company"
                      placeholder="Enter the company name"
                      type="text"
                      errorMessage={errors?.company?.message}
                      {...field}
                      ref={null}
                    />
                  </Box>
                )}
              />
              <Controller
                control={control}
                name="title"
                render={({ field }) => (
                  <Box className={classes.input}>
                    <FormTextField
                      label="Title"
                      placeholder="Enter the title of this email"
                      type="text"
                      errorMessage={errors?.title?.message}
                      {...field}
                      ref={null}
                    />
                  </Box>
                )}
              />
              <Controller
                control={control}
                name="phone"
                render={({ field }) => (
                  <Box className={classes.input}>
                    <FormTextField
                      label="Phone Number"
                      placeholder="Enter your phone number"
                      type=""
                      errorMessage={errors?.phone?.message}
                      {...field}
                      ref={null}
                    />
                  </Box>
                )}
              />
              <Controller
                control={control}
                name="fax"
                render={({ field }) => (
                  <Box className={classes.input}>
                    <FormTextField
                      label="Fax"
                      placeholder="Enter your fax number"
                      type="text"
                      errorMessage={errors?.fax?.message}
                      {...field}
                      ref={null}
                    />
                  </Box>
                )}
              />
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <Box className={classes.input}>
                    <FormTextField
                      label="Email"
                      placeholder="Enter your email address"
                      type="email"
                      errorMessage={errors?.email?.message}
                      {...field}
                      ref={null}
                    />
                  </Box>
                )}
              />
              <Controller
                control={control}
                name="website"
                render={({ field }) => (
                  <Box className={classes.input}>
                    <FormTextField
                      label="Website"
                      placeholder="Enter your website address"
                      type="text"
                      errorMessage={errors?.website?.message}
                      {...field}
                      ref={null}
                    />
                  </Box>
                )}
              />
              <Controller
                control={control}
                name="address"
                render={({ field }) => (
                  <Box className={classes.input}>
                    <FormTextField
                      label="Address"
                      placeholder="Enter your street address"
                      type="text"
                      errorMessage={errors?.address?.message}
                      {...field}
                      ref={null}
                    />
                  </Box>
                )}
              />
              <Controller
                control={control}
                name="address2"
                render={({ field }) => (
                  <Box className={classes.input}>
                    <FormTextField
                      label="Address 2"
                      placeholder="Additional address information"
                      type="text"
                      errorMessage={errors?.address2?.message}
                      {...field}
                      ref={null}
                    />
                  </Box>
                )}
              />
              <Controller
                control={control}
                name="city"
                render={({ field }) => (
                  <Box className={classes.input}>
                    <FormTextField
                      label="City"
                      placeholder="City"
                      type="text"
                      errorMessage={errors?.city?.message}
                      {...field}
                      ref={null}
                    />
                  </Box>
                )}
              />
              <Controller
                control={control}
                name="stateProvince"
                render={({ field }) => (
                  <Box className={classes.input}>
                    <FormTextField
                      label="State/Province"
                      placeholder="State or Province"
                      type="text"
                      errorMessage={errors?.stateProvince?.message}
                      {...field}
                      ref={null}
                    />
                  </Box>
                )}
              />
              <Controller
                control={control}
                name="postalCode"
                render={({ field }) => (
                  <Box className={classes.input}>
                    <FormTextField
                      label="Postal Code"
                      placeholder="Postal Code"
                      type="text"
                      errorMessage={errors?.postalCode?.message}
                      {...field}
                      ref={null}
                    />
                  </Box>
                )}
              />
              <Controller
                control={control}
                name="country"
                render={({ field }) => (
                  <Box className={classes.input}>
                    <FormTextField
                      label="Country"
                      placeholder="Country"
                      type="text"
                      errorMessage={errors?.country?.message}
                      {...field}
                      ref={null}
                    />
                  </Box>
                )}
              />
            </Box>
          </Box>
          <Box className={classes.fullLength}>
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <Box className={classes.input}>
                  <FormTextField
                    label="Description"
                    placeholder="Enter a description"
                    type="text"
                    multiline
                    errorMessage={errors?.description?.message}
                    {...field}
                    ref={null}
                  />
                </Box>
              )}
            />
          </Box>

          {loading && <LoadingSpinner text="Sending your email...." />}
          {/**Had to use this logic to show or hide the response or the uploader */}
          {(!loading && !formSentSuccessfully && isSubmitSuccessful) ||
          (!loading && formSentSuccessfully && isSubmitSuccessful) ? (
            <Typography
              variant="h5"
              color={formSentSuccessfully ? 'green' : 'red'}
            >
              {formSentSuccessfully
                ? ' Email Sent Successfully!'
                : 'Form Submission Failed'}
            </Typography>
          ) : (
            <Box className={classes.fullLength}>
              <Controller
                control={control}
                name="file"
                render={({ field }) => (
                  <Box className={classes.input}>
                    <HTMLFileUploader field={field} />
                  </Box>
                )}
              />
            </Box>
          )}
          <Box className={classes.fullLength}>
            <Controller
              control={control}
              name="selectedFile"
              render={({ field }) => (
                <Box className={classes.input}>
                  <FormTextField
                    label="Uploaded File"
                    placeholder="Enter a file"
                    type="text"
                    multiline
                    errorMessage={errors?.selectedFile?.message}
                    {...field}
                    ref={null}
                    style={{
                      display: 'none',
                    }}
                  />
                </Box>
              )}
            />
          </Box>
          {process.env.NODE_ENV === 'development' && (
            <DevTool control={control} />
          )}
        </Box>
      )}
    </FormComponent>
  );
};

export default VendorForm;
