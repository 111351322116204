import React from 'react';

import {
  DeepPartial,
  FieldValues,
  SubmitErrorHandler,
  SubmitHandler,
  UnpackNestedValue,
  UseFormReturn,
  ValidationMode,
  useForm,
} from 'react-hook-form';

import FormComponentTemplate, {
  FormComponentTemplateProps,
} from './FormComponent';

import { yupResolver } from '@hookform/resolvers/yup';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// type NoInfer<T> = [T][T extends any ? 0 : never];
interface FormComponentProps<T> {
  formName: string;
  formHeader?: string;
  formDescription?: string;
  initialValue: UnpackNestedValue<DeepPartial<T>>;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  onSubmit: SubmitHandler<FieldValues>;
  onCancel: () => void;
  children:
    | ((
        form: UseFormReturn<FieldValues>,
        onSubmit: SubmitHandler<FieldValues>
      ) => React.ReactNode)
    | React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationSchema?: any;
  mode?: keyof ValidationMode | undefined;
  onError?: SubmitErrorHandler<FieldValues>;
  closeButton?: boolean;
  submitButtonText?: string;
  cancelButtonText?: string;
  submitDisabled?: boolean;
  hideFormCTAOnSuccess?: boolean;
}

const useFormHooks = <T,>({
  formName,
  initialValue,
  onCancel,
  onSubmit,
  formDescription,
  formHeader,
  children,
  validationSchema,
  mode,
  onError,
  closeButton,
  cancelButtonText = 'Cancel',
  submitButtonText = 'Submit',
  submitDisabled,
  hideFormCTAOnSuccess,
}: FormComponentProps<T>): FormComponentTemplateProps<T> => {
  const form = useForm<FieldValues>({
    defaultValues: initialValue,
    mode,
    ...(validationSchema ? { resolver: yupResolver(validationSchema) } : {}),
  });

  return {
    formName,
    initialValue,
    onCancel,
    onSubmit,
    formDescription,
    formHeader,
    children,
    validationSchema,
    mode,
    onError,
    form,
    closeButton,
    cancelButtonText,
    submitButtonText,
    submitDisabled,
    hideFormCTAOnSuccess,
  };
};

const FormComponent = <T,>(props: FormComponentProps<T>): JSX.Element => (
  <FormComponentTemplate {...useFormHooks<T>(props)} />
);

export default FormComponent;
