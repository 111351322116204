import { Box, Button, Typography, TypographyOwnProps } from '@mui/material';
import React from 'react';
import useToutStyles from './styles';

interface ToutTemplateProps {
  imageUrl?: string;
  title?: string;
  bodyText?: string;
  bodyTextColor?: TypographyOwnProps['color'];
  bodyTextVariant?: TypographyOwnProps['variant'];
  ctaConfig?: {
    text: string;
    onCLick?: () => void;
  };
  flexDirection?: 'row-reverse' | 'column-reverse' | 'column' | 'row';
  alignToutContent?:
    | 'center'
    | 'normal'
    | 'baseline'
    | 'flex-start'
    | 'flex-end';
  children?: React.ReactNode;
}

const ToutTemplate: React.FC<ToutTemplateProps> = ({
  bodyText,
  bodyTextVariant = 'h4',
  bodyTextColor = 'GrayText',
  ctaConfig,
  imageUrl,
  title,
  flexDirection = 'row',
  alignToutContent = 'center',
  children,
}) => {
  const { classes } = useToutStyles();
  return (
    <Box
      className={classes.mainToutWrap}
      flexDirection={flexDirection}
      alignItems={alignToutContent}
    >
      <Box>
        <img
          className={classes.image}
          src={imageUrl || 'https://placedog.net/500/280'}
          title={title}
          alt="tout"
        />
      </Box>
      <Box>
        {title && (
          <Typography mb={3} variant={bodyTextVariant} color="MenuText">
            {title}
          </Typography>
        )}
        {bodyText && (
          <Typography mb={3} variant="body1" color={bodyTextColor}>
            {bodyText}
          </Typography>
        )}
        {children && <Box>{children}</Box>}
        {ctaConfig && (
          <Button
            variant="contained"
            color="primary"
            onClick={ctaConfig?.onCLick}
          >
            {ctaConfig?.text}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ToutTemplate;
