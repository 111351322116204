import { Box, Typography } from '@mui/material';
import React from 'react';
import Tout from '../Tout';
import lost from '../../assets/lost-in-forest.jpeg';

const Page404: React.FC = () => {
  return (
    <Box>
      <Typography mb={4} style={{ textAlign: 'left' }} variant="h2">
        404:
      </Typography>
      <Tout
        imageUrl={lost}
        // flexDirection="column"
        title="Did you get lost?"
        bodyText="The page you are looking for is in a different part of the woods.  Journey back brave traveler."
        alignToutContent="center"
      />
    </Box>
  );
};

export default Page404;
