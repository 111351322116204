import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import Header from '../components/Header';
import PageRouter from '../Router/PageRouter';
import Footer from '../components/Footer';
import headerData, { mobileHeaderData } from './headerLinks';
import usePageWrapperStyles from './pageWrapperStyles';

const PageWrapper: React.FC = () => {
  const { classes } = usePageWrapperStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      flexDirection={'column'}
      height={'100vh'}
    >
      <Box>
        <Header links={!isMobile ? headerData : mobileHeaderData} />
      </Box>
      {/* Push the footer all the way to the bottom with flexGrow 1 on this element*/}
      <Box flexGrow={1} className={classes.pageWrapperMain}>
        <PageRouter />
      </Box>
      <Footer />
    </Box>
  );
};

export default PageWrapper;
