import React from 'react';

import { Route, Routes } from 'react-router-dom';
import HomePage from '../pages/Home';
import Vendors from '../pages/Vendors';
import SpecialtyPage from '../pages/Specialty';
import AboutPage from '../pages/About';
import CareersPage from '../pages/Careers';
import ContactPage from '../pages/Contact';
import Page404 from '../components/Page404/Page404';
import OurCustomers from '../pages/OurCustomers';

const PageRouter: React.FC = () => {
  return (
    <>
      {/* <RouterProvider router={routes} /> */}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/products" element={<SpecialtyPage />} />
        <Route path="/customers" element={<OurCustomers />} />
        <Route path="/vendors" element={<Vendors />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/careers" element={<CareersPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </>
  );
};

export default PageRouter;
