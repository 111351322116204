import withHooks, { mapHooksToProps } from '../../common/utilities/withHooks';

import UnderConstructionTemplate from './UnderConstruction';
interface UnderConstructionProps {
  title?: string;
}

const hooks = mapHooksToProps(
  ({ title = 'Under Construction' }: UnderConstructionProps) => {
    return {
      title,
      mainTextContent: [
        'We are currently working on this page. Please check back soon.',
      ],
    };
  }
);

const UnderConstructionPage = withHooks(UnderConstructionTemplate, hooks);
export default UnderConstructionPage;
