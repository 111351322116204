import withHooks, { mapHooksToProps } from '../../common/utilities/withHooks';
import CareersPageTemplate from './CareersPage';

import officeWorker from '../../assets/office-worker.jpg';

export type DistributorLinks = {
  image: string;
  url: string;
};
export interface CareersPageData {
  title: string;
  distributors: {
    title: string;
    links: DistributorLinks[];
  };
  textContent: {
    main: string[];
    title: string;
    images?: string[];
  };
}

const hooks = mapHooksToProps(() => {
  const CareersPageData: CareersPageData = {
    title: 'Careers',
    distributors: {
      links: [
        {
          image: '',
          url: '',
        },
      ],
      title: 'Distributors',
    },
    textContent: {
      main: [
        `Gourmet Merchants International is an Equal Opportunity Employer and always looking for intelligent, energetic and talented people. If you have experience working in food retail, distribution and/or sales, please complete our`,
      ],
      title: 'Careers',
      images: [officeWorker],
    },
  };

  return CareersPageData;
});

const CareersPage = withHooks(CareersPageTemplate, hooks);

export default CareersPage;
