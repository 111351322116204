import React from 'react';
import { PageImages } from '.';

import { Box, Typography } from '@mui/material';
import Tout from '../../components/Tout';
import pastaImage from '../../assets/pasta.jpg';
import jamImage from '../../assets/jam_1920.jpg';

interface SpecialtyPageProps {
  pageText: string[];
  pageImages: PageImages[];
}

const SpecialtyPageTemplate: React.FC<SpecialtyPageProps> = ({
  pageImages,
  pageText,
}) => {
  return (
    <main>
      <article>
        <Typography variant="h4">Specialty Products</Typography>
      </article>
      <section>
        <Box>
          {pageText.map((text, idx) => (
            <p key={`specialty_${idx}`}>
              <Typography>{text}</Typography>
            </p>
          ))}
        </Box>
        <Box mb={3} mt={3}>
          <Tout
            bodyText="Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,"
            ctaConfig={{
              text: 'Learn More',
              onCLick: () => console.log('You clicked the CTA!'),
            }}
            imageUrl={pastaImage}
            title="Fine artisan pastas from the worlds most recognized brands"
          />
        </Box>
        <Box>
          <Tout
            bodyText="Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,"
            ctaConfig={{
              text: 'Learn More',
              onCLick: () => console.log('You clicked the CTA!'),
            }}
            imageUrl={jamImage}
            title="Want to become a vendor? Find out how"
            flexDirection="row-reverse"
          />
        </Box>
      </section>
    </main>
  );
};

export default SpecialtyPageTemplate;
