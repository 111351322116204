import { makeStyles } from 'tss-react/mui';

const useLoadingSpinnerStyles = makeStyles()((theme) => ({
  spinner: {
    fontSize: '2.5rem',
    animation: 'spin 3s linear infinite',
    pointerEvents: 'none',
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  },
}));

export default useLoadingSpinnerStyles;
