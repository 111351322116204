import { makeStyles } from 'tss-react/mui';

const useHomePageStyles = makeStyles()((theme) => ({
  mainWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexShrink: 0,
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column-reverse',
      gap: 24,
    },
  },
  sideNav: {
    width: '33%',
    flexShrink: 0,
    maxWidth: theme.spacing(40),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
  mainContent: {
    maxWidth: theme.spacing(176),
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: 24,
    },
    '&>div>article': {
      marginBottom: theme.spacing(3),
      '&>a': {
        color: '#e4bb3d',
      },
    },
  },
  mainContentWrapper: {
    marginLeft: theme.spacing(4),
    minWidth: '70%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: 0,
    },
  },
  mainImage: {
    display: 'block',
    width: '60%',
    height: 'fit-content',
    maxWidth: 738,
    borderRadius: 10,
    marginLeft: theme.spacing(9),
    [theme.breakpoints.down('lg')]: {
      width: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 0,
    },
  },
}));

export default useHomePageStyles;
