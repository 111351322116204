import React from 'react';
import { Box } from '@mui/material';
import usePartnerListStyles from './styles';

export type Partner = {
  image: string;
  url: string;
  alt: string;
};

interface PartnerListProps {
  list: Partner[];
  openUrl: (e: React.MouseEvent<HTMLDivElement>, url: string) => void;
}

const PartnerListTemplate = ({
  list,
  openUrl,
}: PartnerListProps): JSX.Element => {
  const { classes } = usePartnerListStyles();
  return (
    <Box className={classes.main}>
      {list.map((partner, idx) => (
        //@ts-ignore
        <Box
          onClick={openUrl}
          className={classes.partner}
          key={`partner_${idx}`}
          data-url={`${partner.url}`}
        >
          <img src={partner.image} alt={partner.alt} />
        </Box>
      ))}
    </Box>
  );
};

export default PartnerListTemplate;
