import React from 'react';
import useFooterStyles from './styles';

interface FooterTemplateProps {
  footerData: string[] | JSX.Element[];
}

const Footer = ({ footerData }: FooterTemplateProps): JSX.Element => {
  const { classes } = useFooterStyles();
  return (
    <footer className={classes.footer}>
      <ul className={classes.footerContent}>
        {footerData.map((content, idx) => (
          <li key={`footer_${idx}`}>{content}</li>
        ))}
      </ul>
    </footer>
  );
};

export default Footer;
