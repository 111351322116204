import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import {
  ControllerRenderProps,
  FieldValues,
  useFormContext,
  UseFormRegister,
} from 'react-hook-form';
import useHtmlFileUploaderStyles from './htmlFileUploaderStyles';
import { ArrayOfAcceptedFileTypes, UploadedFile } from '.';

interface HTMLFileUploaderTemplateProps {
  field: ControllerRenderProps<FieldValues, 'file'>;
  acceptedTypes?: ArrayOfAcceptedFileTypes;
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  fileResult: UploadedFile;
  register: UseFormRegister<FieldValues>;
}

const HTMLFileUploaderTemplate: React.FC<HTMLFileUploaderTemplateProps> = ({
  field,
  acceptedTypes = ['image/png', ' image/jpeg'],
  onChange,
  fileResult,
  register,
}) => {
  const { classes } = useHtmlFileUploaderStyles();
  const { ref, onChange: onChangeRegister, ...rest } = register(field.name);

  const { onChange: fieldOnchange } = field;
  const triggerInput = () => {
    const input = document.getElementById('file-upload');
    input?.click();
  };
  const { getFieldState } = useFormContext();
  // Used to show or hide the image and name of the file in the UI
  const dirtyField = getFieldState(field.name).isDirty;

  return (
    <Box className={classes.main}>
      <Box>
        <Box>
          <Typography component={'h5'} variant="h6" mb={2}>
            Optional image upload
          </Typography>
        </Box>
        <Button onClick={triggerInput} variant="contained" color="primary">
          Upload File
        </Button>
      </Box>
      {fileResult.fileName && dirtyField && (
        <Box className={classes.imageBox}>
          <Typography variant="body2" mb={2}>
            File Name: {fileResult.fileName}
          </Typography>
          <img
            className={classes.fileImage}
            src={fileResult.blob}
            alt="File upload"
          />
        </Box>
      )}
      <input
        // {...field} //Seems to cause problems
        {...rest}
        type="file"
        accept={acceptedTypes?.join(',')}
        id="file-upload"
        onChange={(evt) => {
          // Needed for react-hook-form to process
          fieldOnchange(evt.target?.files?.[0]);

          // onChange from the index.ts file
          onChange(evt);
        }}
        ref={ref}
        hidden
      />
    </Box>
  );
};

export default HTMLFileUploaderTemplate;
