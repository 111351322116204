import React from 'react';

import useCareersPageStyles from './careersPageStyles';
import { Box, Button, Typography } from '@mui/material';
import PartnerList from '../../components/PartnerList';
import { CareersPageData } from '.';
import Tout from '../../components/Tout';
//@ts-ignore
import pdf from '../../assets/pdf/application.pdf';

const CareersPage: React.FC<CareersPageData> = ({
  distributors,
  textContent,
  title,
}) => {
  const { classes } = useCareersPageStyles();

  const openPDF = () => {
    window && window.open(pdf, '_blank');

    console.log('textContent.main', textContent.main);
  };

  return (
    <main>
      <section className={classes.mainWrapper}>
        <Box className={classes.sideNav}>
          <nav>
            <PartnerList />
          </nav>
        </Box>
        <Box className={classes.mainContentWrapper}>
          <Typography variant="h4">{textContent.title}</Typography>
          <Box className={classes.mainContent}>
            <article>
              {textContent.main.map((content, idx) => (
                <Box mb={4} key={`tout_${idx}`}>
                  <Tout
                    bodyText={content}
                    bodyTextVariant="body1"
                    bodyTextColor="MenuText"
                    flexDirection="row-reverse"
                    imageUrl={textContent.images?.[0]}
                    alignToutContent="normal"
                  >
                    <Box>
                      If you have experience working in food retail,
                      distribution and/or sales, please complete our{' '}
                      <strong>Application for Employment</strong>, then e-mail
                      with your resume to <strong>hr@gmidist.com</strong> via
                      the buttons below:
                    </Box>
                    <Box className={classes.ctaBox}>
                      <Button onClick={openPDF} variant="contained">
                        Application
                      </Button>
                      <Button href="mailto:hr@gmidist.com" variant="contained">
                        hr@gmidist.com
                      </Button>
                    </Box>
                  </Tout>
                </Box>
              ))}
            </article>
            {/* <img className={classes.mainImage} src={iceCream} alt="ice-cream" /> */}
            {/* <a href="https://www.vecteezy.com/free-photos/dessert">
              Dessert Stock photos by Vecteezy
            </a> */}
          </Box>
        </Box>
      </section>
    </main>
  );
};

export default CareersPage;
