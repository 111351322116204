import { makeStyles } from 'tss-react/mui';

const usePageWrapperStyles = makeStyles()((theme) => ({
  pageWrapperMain: {
    padding: theme.spacing(6, 9, 6, 9),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 4, 3, 4),
    },
  },
}));

export default usePageWrapperStyles;
