import { makeStyles } from 'tss-react/mui';

const useHeaderStyles = makeStyles()((theme) => ({
  mainBody: {
    width: 'auto',
    padding: theme.spacing(4, 8, 2.5, 8),
    boxShadow: '0 3px 20px #333',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    [theme.breakpoints.down('xl')]: {
      padding: theme.spacing(2, 2, 2.5, 2),
      fontSize: '2rem',
    },
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(2, 2, 2.5, 2),
      fontSize: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'inherit',
      padding: theme.spacing(2, 2, 2.5, 2),
    },
    background: '#000',
    '&>nav>ul': {
      listStyle: 'none',
    },
    '&>nav>ul>li': {
      marginRight: theme.spacing(3),
    },
    '&>nav>ul>li:last-child': {
      marginRight: 0,
    },
  },
  dialogMobileSpacer: {
    paddingTop: theme.spacing(6),
    background: '#000',
    height: 'fill-available',
  },
  linkWrapper: {
    margin: 0,
    padding: 0,
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      display: 'block',
      background: '#000000',
      height: 'fill-available',
    },
  },
  link: {
    color: theme.palette.background.paper,
    fontSize: theme.spacing(3),
    textDecoration: 'none',
    '&:hover': {
      color: '#e4bb3d',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginTop: theme.spacing(3),
      borderBottom: 'solid 1px #333',
      paddingBottom: theme.spacing(3),
    },

    [theme.breakpoints.down('md')]: {
      fontSize: theme.spacing(2.3),
    }
  },
  hamburger: {
    color: theme.palette.background.paper,
    fontSize: theme.spacing(6),
  },
  dialog: {
    background: '#000',
    padding: theme.spacing(3),
  },
  closeIcon: {
    position: 'absolute',
    color: theme.palette.background.paper,
    right: 0,
    fontSize: '2rem',
  },
}));

export default useHeaderStyles;
